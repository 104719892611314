import React from 'react';
import stringReplace from 'react-string-replace';

import { withError } from '../../../shared/components/error-boundary';
import Countdown from '../../../shared/components/countdown';
import useSiteConfig from '../../../hooks/useSiteConfig';
import usePageComponents from '../../../hooks/usePageComponents';
import { Helpers } from '../../../core/src/helpers';

import './intra-day-banner.less';

const getCountDown = (activeDeal, dateFormat, hideTimeUnitName) => {
    const { endDate } = activeDeal;

    return <Countdown endtime={endDate} format={dateFormat} hideTimeUnitName={hideTimeUnitName} />;
};

export const getDealsMessagingConfig = (siteConfig, pageConfig) => {
    const {
        defaultMessaging = 'Flash Sale Ending Soon',
        qtyLimitMessage = '{limit} Unit Flash Sale',
        unitMessage = 'Units left at this price',
        dateFormat = 'd?:h?:m?:s',
        hideTimeUnitName = true,
    } = siteConfig || {};
    const {
        defaultMessaging: pDefaultMsg,
        qtyLimitMessage: pQtyMsg,
        unitMessage: pUnitMsg,
        dateFormat: pDateFormat,
        hideTimeUnitName: pHideTimeUnitName,
    } = pageConfig || {};

    return {
        defaultMessaging: pDefaultMsg || defaultMessaging,
        qtyLimitMessage: pQtyMsg || qtyLimitMessage,
        unitMessage: pUnitMsg || unitMessage,
        dateFormat: pDateFormat || dateFormat,
        hideTimeUnitName: typeof pHideTimeUnitName !== 'undefined' ? pHideTimeUnitName : hideTimeUnitName,
    };
};

export const getDynamicMessaging = (activeDeal, price, dateFormat, hideTimeUnitName, message) => {
    const { limit, unitsLeft } = activeDeal;
    //match price or percentage discount in message
    message = message.replace(/({\$})|({%})/g, match => {
        if (!price) {
            return '';
        }
        const { discount, discountPercent } = Helpers.getPriceDiscount(price);
        if (match === '{%}') {
            return discount > 0 ? `${Math.floor(discountPercent)}%` : '';
        } else if (match === '{$}') {
            return discount > 0 ? `$${Math.floor(discount)}` : '';
        }
    });

    //match limit
    message = stringReplace(message, new RegExp(/\{limit}/g), (match, i) => {
        if (limit === -1) {
            return '';
        }
        return (
            <React.Fragment key={`redemption-limit-${i}`}>
                <span className="redemption-limit">{limit}</span>
                {match}
            </React.Fragment>
        );
    });

    //match unitsLeft
    message = stringReplace(message, new RegExp(/\{unitsLeft}/g), (match, i) => {
        if (unitsLeft === -1) {
            return '';
        }
        return (
            <React.Fragment key={`units-left-${i}`}>
                <span className="units-left">{unitsLeft}</span>
                {match}
            </React.Fragment>
        );
    });

    //match time left
    message = stringReplace(message, new RegExp(/\{timeLeft}/g), (match, i) => {
        return (
            <React.Fragment key={`time-left-${i}`}>
                {getCountDown(activeDeal, dateFormat, hideTimeUnitName)}
                {match}
            </React.Fragment>
        );
    });

    //match new line
    message = stringReplace(message, new RegExp(/\{br}/g), (match, i) => {
        return (
            <React.Fragment key={`new-line-${i}`}>
                <br />
                {match}
            </React.Fragment>
        );
    });

    return message;
};

const getCustomMessage = (defaultMessaging, message) => {
    if (!message) {
        return defaultMessaging;
    }
    //add support for old custom messages that automatically included the time remaining
    if (!/(\{\w+\})/gi.test(message)) {
        return `${message} {timeLeft}`;
    }
    return message;
};

export default withError(({ activeDeal, price, message, children }) => {
    const { intraDayDeal: globalDealsSettings } = useSiteConfig();
    const { intraDayDeals } = usePageComponents(['intraDayDeals']);
    const { defaultMessaging, qtyLimitMessage, dateFormat, hideTimeUnitName } = getDealsMessagingConfig(
        globalDealsSettings,
        intraDayDeals,
    );
    if (!activeDeal && !price.deal) {
        return null;
    }
    //price data can contain the active deal
    const deal = activeDeal || (price && price.deal && price.deal.activeDeal);

    //if custom message provided, override set config settings
    if (message) {
        const customMessage = (
            <span className="deals-countdown">
                {getDynamicMessaging(
                    deal,
                    price,
                    dateFormat,
                    hideTimeUnitName,
                    getCustomMessage(defaultMessaging, message),
                )}
            </span>
        );
        return children ? children({ dealCallout: customMessage }) : customMessage;
    }

    const dealCallout = (
        <>
            <span className="deals-countdown">
                {getDynamicMessaging(deal, price, dateFormat, hideTimeUnitName, defaultMessaging)}
            </span>
            {qtyLimitMessage && (
                <span className="deals-callout">
                    {getDynamicMessaging(deal, price, dateFormat, hideTimeUnitName, qtyLimitMessage)}
                </span>
            )}
        </>
    );
    return children ? children({ dealCallout }) : dealCallout;
});
