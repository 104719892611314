import React from 'react';
import { Violator } from '@hpstellar/core';
import { Violator as VisIDViolator } from '@hpstellar/core/visId';

import DealCallout from './deal-callout';

import './deal-violator.less';

export default ({
    className = 'primary',
    small = true,
    variation = 'offers',
    variant = 'caption',
    theme = 'light',
    noViolator,
    visId,
    backgroundColor = 'red',
    children,
    ...dealProps
}) => {
    if (noViolator) {
        return <DealCallout {...dealProps} />;
    }
    if (visId) {
        return (
            <DealCallout {...dealProps}>
                {({ dealCallout }) => {
                    return (
                        <VisIDViolator
                            className={`deal-violator ${className}`}
                            small={small}
                            variant={variant}
                            label={children || dealCallout}
                            backgroundColor={backgroundColor}
                            theme={theme}
                        />
                    );
                }}
            </DealCallout>
        );
    }
    return (
        <Violator className={`deal-violator ${className}`} small={small} variation={variation}>
            {children ? children : <DealCallout {...dealProps} />}
        </Violator>
    );
};
